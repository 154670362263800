import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import UrlHelper from '@lib/UrlHelper'
import feedApi from '@redux/api/feedApi'
import { selectMediaTypes } from '@redux/slices/appSlice'
import { feedConstants, selectFeedUrls, selectFilteredIds, setCurrentFeedItemKey } from '@redux/slices/feedSlice'
import { useAppSelector } from '@redux/store/store'

const LoggedInHome = () => {
  const {
    identifier: { slug },
    hasReachedEnd,
    _instanceId,
  } = useAppSelector(state => state.feed)

  const mediaTypes = useAppSelector(selectMediaTypes)
  const filterIds = useAppSelector(selectFilteredIds)

  const dispatch = useDispatch()
  const router = useRouter()
  const { isAddUrlModalOpen } = useAppSelector(state => state.addUrlModal)

  const { isLoading } = feedApi.useGetFeedDataQuery(
    {
      page: 1,
      pageSize: feedConstants.FULL_SCREEN_PAGE_SIZE,
      slug,
      mediaTypes: Array.from(mediaTypes),
      filterIds,
      _instanceId,
      contextId: null,
    },
    { skip: hasReachedEnd }
  )

  const urls = useAppSelector(selectFeedUrls)
  const firstUrlId = urls?.[0]?.url_id

  useEffect(() => {
    if (!isLoading && firstUrlId && !isAddUrlModalOpen) {
      dispatch(setCurrentFeedItemKey(firstUrlId))

      router
        .push(UrlHelper.urlIDPath({ urlID: firstUrlId, skipSSR: true }), UrlHelper.urlIDPath({ urlID: firstUrlId }), {
          shallow: true,
        })
        .catch(() => {})
    }
  }, [dispatch, router, firstUrlId, isAddUrlModalOpen, isLoading])

  return null
}

export default LoggedInHome
