import Image from 'next/image'
import Link from 'next/link'
import { forwardRef } from 'react'

import { AuthLink } from '@desktop/login/AuthLink'
import { useIsDesktop } from '@hooks/useIsDesktop'
import IconApple from '@icons/IconApple.svg'
import IconFacebook from '@icons/IconAuthFacebook.svg'
import IconTwitter from '@icons/IconAuthTwitter.svg'
import IconEmail from '@icons/IconEmail.svg'
import IconSpinner from '@icons/IconSpinner.svg'
import { setIsLoginModalOpen, setLoginModalInnerUI } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import classes from '@styles/Home.module.css'
import { curatorCount } from '../utils'
import Taglines from './tagline'

interface LoggedOutHeroProps {
  isHeroInView: boolean
}

export const LoggedOutHero = forwardRef<HTMLDivElement | null, LoggedOutHeroProps>(({ isHeroInView }, ref) => {
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const { element: TaglineElement } = Taglines[currentPath as keyof typeof Taglines] ?? Taglines.default
  const isDesktop = useIsDesktop()
  const dispatch = useAppDispatch()

  return (
    <>
      <div className="relative flex min-h-[70vh] flex-col overflow-hidden bg-black" ref={ref}>
        <div className="container relative z-10 mx-auto flex h-20 shrink-0 items-center justify-center px-6">
          {isDesktop && (
            <Link href="/">
              <Image src="/logo_white.svg" alt="Mix" height={40} width={120} />
            </Link>
          )}
        </div>
        <div className="absolute inset-0 flex items-center justify-center transition-all sm:top-4 md:top-40 lg:top-56">
          <video
            muted
            autoPlay
            hidden={!isHeroInView}
            loop
            playsInline
            className="mx-auto mt-[-30vh] scale-[2] md:scale-125 lg:mt-[-15vh] xl:mt-[-20vh]"
          >
            <source src="https://assets.mix.com/static/onboarding/intro_desktop.webm" type="video/webm" />
            <source src="https://assets.mix.com/static/onboarding/intro_desktop.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="relative flex flex-1 flex-col items-center justify-center lg:pt-10">
          <div className="relative px-6 pt-24">
            <div className="relative">
              <div className={classes.animateFlyLongReverse}>
                <div className="relative z-10 text-center transition-opacity">
                  <TaglineElement />
                  <div className="mb-8 flex flex-col items-center space-y-4">
                    <div
                      className="g_id_signin animate-breathing overflow-hidden rounded-full bg-white"
                      style={{
                        height: '40px',
                        ['--breathing-scale-min' as string]: '1.2',
                        ['--breathing-scale-max' as string]: '1.4',
                      }}
                      data-type="standard"
                      data-size="large"
                      data-theme="outline"
                      data-text="continue_with"
                      data-shape="pill"
                      data-logo_alignment="left"
                    />
                    <div className="text-xl font-medium text-[#999999]">or continue with...</div>
                    <div className="flex space-x-2">
                      <AuthLink name="Facebook" buttonClass="bg-white text-black font-medium text-base">
                        {isSubmitting => (
                          <>
                            {isSubmitting ? (
                              <IconSpinner className="h-6" />
                            ) : (
                              <span className="w-6">
                                <IconFacebook />
                              </span>
                            )}
                          </>
                        )}
                      </AuthLink>
                      <AuthLink name="Twitter" buttonClass="bg-white text-black font-medium text-base">
                        {isSubmitting => (
                          <>
                            {isSubmitting ? (
                              <IconSpinner className="h-6" />
                            ) : (
                              <span className="w-6">
                                <IconTwitter />
                              </span>
                            )}
                          </>
                        )}
                      </AuthLink>
                      <AuthLink name="Apple" buttonClass="bg-white text-black font-medium text-base">
                        {isSubmitting => (
                          <>
                            {isSubmitting ? (
                              <IconSpinner className="h-6" />
                            ) : (
                              <span className="w-6">
                                <IconApple />
                              </span>
                            )}
                          </>
                        )}
                      </AuthLink>
                      <button
                        onClick={() => {
                          dispatch(setLoginModalInnerUI('email'))
                          dispatch(setIsLoginModalOpen(true))
                        }}
                        className={`btn btn-square flex cursor-pointer items-center space-x-4 bg-white p-4 text-base font-medium text-black`}
                      >
                        <span className="w-6">
                          <IconEmail />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-auto mb-12 max-w-[65vw] text-xs font-medium uppercase tracking-wider text-accent">
                    Explore some of the gems discovered by <span className="proportional-nums">{curatorCount()}</span>{' '}
                    curators
                  </div>
                </div>
                <span className="absolute inset-x-0 top-0 h-24 bg-black blur-2xl"></span>
                <span className="absolute inset-0 bg-black opacity-50 blur-2xl"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

LoggedOutHero.displayName = 'LoggedOutHero'
