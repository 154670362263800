import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useTheme } from '@context/ThemeContext'
import Footer from '@desktop/Footer'
import LoggedOutHeader from '@desktop/header/LoggedOutHeader'
import { GridFeed } from '@desktop/layouts/GridFeedlLayout'
import { MetaTags } from '@desktop/MetaTags'
import { useTrackPageView } from '@hooks/analytics/useTrackPageView'
import { useIsDesktop } from '@hooks/useIsDesktop'
import runtimeConfig from '@lib/RuntimeConfig'
import VerticalFeed from '@mobile/VerticalFeed'
import { VideoPlayerContextProvider } from '@pages/url/components/VideoPlayerContext'
import feedApi from '@redux/api/feedApi'
import { selectMediaTypes, setShowGSignIn } from '@redux/slices/appSlice'
import {
  incrementPage,
  selectCurrentFeedItemKey,
  selectFilteredIds,
  setCurrentFeedItemKey,
} from '@redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { LoggedOutHero } from './LoggedOutHero'
import Taglines from './tagline'

const LoggedOutHome = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const {
    page,
    identifier: { slug },
    pageSize,
    hasReachedEnd,
    _instanceId,
  } = useAppSelector(state => state.feed)
  const mediaTypes = useAppSelector(selectMediaTypes)
  const { isLoginModalOpen } = useAppSelector(state => state.loginModal)

  useEffect(() => {
    theme.setForcedTheme('dark')
    return () => theme.setForcedTheme(undefined)
  }, [theme, theme.resolvedTheme])

  const { ref: heroRef, inView: isHeroInView } = useInView({
    threshold: 0.1,
    initialInView: true,
    onChange: inView => {
      if (!inView) dispatch(setShowGSignIn(true))
    },
  })

  useTrackPageView()

  const filterIds = useAppSelector(selectFilteredIds)
  const {
    data: feed,
    isFetching,
    isLoading,
  } = feedApi.useGetFeedDataQuery(
    {
      page,
      pageSize,
      slug,
      mediaTypes: Array.from(mediaTypes),
      filterIds,
      _instanceId,
      contextId: null,
    },
    { skip: hasReachedEnd || isLoginModalOpen }
  )

  const { items } = feed ?? {}

  const currentFeedItemKey = useAppSelector(selectCurrentFeedItemKey)

  const onGridItemClick = useCallback(
    (feedItemKey: string) => {
      const queryString = `?skipSSR=true`
      const asPath = `/!${feedItemKey}`
      const href = `/!${feedItemKey}${queryString}`
      dispatch(setCurrentFeedItemKey(feedItemKey))
      router.push(href, asPath).catch(() => {})
    },
    [dispatch, router]
  )

  const { copy: TaglineCopy } = Taglines[currentPath as keyof typeof Taglines] ?? Taglines.default
  const loadMore = useCallback(() => {
    dispatch(incrementPage(1))
  }, [dispatch])

  const isDesktop = useIsDesktop()

  return (
    <>
      <MetaTags
        title={`Mix — ${TaglineCopy.heading}`}
        description={TaglineCopy.subheading}
        permalink={runtimeConfig().publicRuntimeConfig.baseUrl}
      />
      {isDesktop ? (
        <>
          <div className="grow">
            <LoggedOutHero isHeroInView={isHeroInView} ref={heroRef} />
            <div>
              <div
                className={`sticky top-0 z-30 opacity-0 transition-all duration-300 ease-in ${
                  !isHeroInView ? 'translate-y-0 opacity-100' : '-translate-y-full'
                }`}
              >
                <LoggedOutHeader />
              </div>
              <div className="flex size-full flex-col overflow-y-scroll scrollbar-hide md:flex-row" id="scrolling-grid">
                <GridFeed
                  loadMore={loadMore}
                  onClick={onGridItemClick}
                  items={items}
                  isLoadingFeed={isLoading || isFetching}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <VideoPlayerContextProvider key={currentFeedItemKey}>
          <VerticalFeed renderLoginSlide />
        </VideoPlayerContextProvider>
      )}
    </>
  )
}

export default LoggedOutHome
