import { useEffect, useState } from 'react'
import { screens } from 'tailwindcss/defaultTheme'
import { useWindowSize } from 'usehooks-ts'

export const BlockWidthConfigs: Record<string, { maxWidth: number; breakpoint: number }> = {
  sm: {
    maxWidth: parseInt(screens.sm),
    breakpoint: 180,
  },
  md: {
    maxWidth: 1000,
    breakpoint: 350,
  },
  lg: {
    maxWidth: 1900,
    breakpoint: 350,
  },
  xl: {
    maxWidth: Infinity,
    breakpoint: 500,
  },
}

export const useTargetBlockWidth = () => {
  const { width: windowWidth } = useWindowSize()
  const [breakpoint, setBreakpoint] = useState<number | null>(null)
  useEffect(() => {
    for (const screenClass in BlockWidthConfigs) {
      if (windowWidth < BlockWidthConfigs[screenClass].maxWidth)
        return setBreakpoint(BlockWidthConfigs[screenClass].breakpoint)
    }
  }, [windowWidth])
  return breakpoint
}
