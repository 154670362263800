import { deleteCookie, getCookie } from 'cookies-next'
import type { GetServerSideProps } from 'next'
import React from 'react'

import { getBrowserInfo } from '@lib/browser'
import { globals } from '@lib/messages/protobuf'
import runtimeConfig from '@lib/RuntimeConfig'
import { IntoNode } from '@models/IntoNode'
import { setCurrentContext } from '@redux/slices/analyticsSlice'
import { setPageSection, setStream } from '@redux/slices/appSlice'
import {
  feedConstants,
  setIdentifier,
  setInstanceId,
  setIsAutoplayEnabled,
  setPageNumber,
  setPageSize,
  setStreamUrlId,
} from '@redux/slices/feedSlice'
import { setHeaderState } from '@redux/slices/headerSlice'
import { setIsAddUrlModalOpen, setUrl } from '@redux/slices/modals/addUrlModalSlice'
import { setLoginModalState } from '@redux/slices/modals/loginModalSlice'
import { AppDispatch, wrapper } from '@redux/store/store'
import LoggedInHome from './components/LoggedInHome'
import LoggedOutHome from './components/LoggedOutHome'

import StreamType = globals.StreamType

const ADD_URL_PATHS = new Set(['mixit', 'su', 'add', 'submit', 'extension'])

interface IHome {
  isLoggedIn: boolean
  isDesktop: boolean
}

const Home = ({ isLoggedIn }: IHome) => {
  return <>{isLoggedIn ? <LoggedInHome /> : <LoggedOutHome />}</>
}

export default Home

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(store => async context => {
  const parsedURL = new URL(runtimeConfig().publicRuntimeConfig.baseUrl + context.req.url)
  const pathComponents = parsedURL.pathname.split('/') // /login -> ['', 'login'], / -> ['', '']
  // Drop a cookie for `/:variation` paths
  const variation = pathComponents[1] || 'default' // '/' -> 'default', '/login' -> 'login'

  const isLoggedIn =
    getCookie(runtimeConfig().publicRuntimeConfig.mixIdentifiedCookieName, {
      req: context.req,
    }) === 'true'

  // Handle return_url flow
  // If the user is logged in, redirect to the return_url
  // The return_url is set by the ?r query param
  const returnUrl = getCookie('return_url', { req: context.req })
  if (returnUrl?.startsWith('/') && returnUrl !== '/' && isLoggedIn) {
    deleteCookie('return_url', { req: context.req, res: context.res })
    return {
      redirect: { destination: returnUrl, permanent: false },
    }
  }
  const browserInfo = getBrowserInfo(context.req.headers['user-agent'] ?? '')

  if (getCookie('is_ipad', { req: context.req }) === 'true') {
    browserInfo.isDesktop = false
    browserInfo.isTablet = true
  }

  const isLoggedOut = !isLoggedIn
  const isLoggedInAndDesktop = isLoggedIn && browserInfo.isDesktop
  const isLoggedInAndMobile = isLoggedIn && !browserInfo.isDesktop

  const dispatch = store.dispatch as AppDispatch
  dispatch(setStreamUrlId(null))
  if (isLoggedOut) {
    dispatch(setPageSection({ value: 'home.loggedOut' }))
    dispatch(setPageNumber(1))
    dispatch(setIdentifier(IntoNode.FEATURED))
    dispatch(setIsAutoplayEnabled(true))
    dispatch(setPageSize(feedConstants.GRID_PAGE_SIZE))
    dispatch(setInstanceId(Date.now().toString()))
    setHeaderState({
      dispatch,
      isDisabled: true,
      isTitleEnabled: false,
      isSharingEnabled: false,
      isUrlActionsEnabled: true,
      isGridActionsEnabled: false,
      isFiltersEnabled: false,
    })

    dispatch(
      setCurrentContext({
        page: 'LOGGED_OUT_HOME',
        pageSection: null,
        streamType: 'UNKNOWN_STREAM_TYPE',
      })
    )

    if (variation === 'login') {
      const returnUrl = context.query.r as string | undefined
      setLoginModalState({
        dispatch,
        copyVariant: 'login',
        returnUrl,
        isLoginModalOpen: true,
        returnPathOnClose: '/',
        loginModalInnerUI: context.query.provider === 'email' ? 'email' : 'default',
      })
    } else if (ADD_URL_PATHS.has(variation)) {
      setLoginModalState({
        dispatch,
        copyVariant: 'add',
        returnUrl: context.req.url,
        returnPathOnClose: '/',
        isLoginModalOpen: true,
      })
    }

    return {
      props: {
        pageName: `LOHP.${variation.toUpperCase()}`,
        isDesktop: browserInfo.isDesktop,
      },
    }
  }

  const _instanceId = Date.now().toString()

  if (isLoggedIn) {
    dispatch(
      setCurrentContext({
        page: 'CONTENT_VIEW',
        pageSection: null,
        streamType: 'TOP_FOR_YOU_STREAM',
      })
    )
    if (ADD_URL_PATHS.has(variation)) {
      dispatch(setIsAddUrlModalOpen(true))
      dispatch(setUrl(parsedURL.searchParams.get('url')))
    }
  }

  if (isLoggedInAndDesktop) {
    setHeaderState({
      dispatch,
      isDisabled: false,
      isTitleEnabled: isLoggedIn,
      isSharingEnabled: isLoggedIn,
      isUrlActionsEnabled: true,
      isGridActionsEnabled: false,
      isFiltersEnabled: isLoggedIn,
    })
    dispatch(setPageSection({ value: 'home.loggedIn' }))
    dispatch(setPageNumber(1))
    dispatch(setIdentifier(IntoNode.FOR_YOU))
    dispatch(setStream(StreamType.NODE_STREAM))
    dispatch(setInstanceId(_instanceId))
    dispatch(setPageSize(feedConstants.FULL_SCREEN_PAGE_SIZE))
    dispatch(setIsAutoplayEnabled(true))
  } else if (isLoggedInAndMobile) {
    setHeaderState({
      dispatch,
      isDisabled: false,
      isTitleEnabled: false,
      isSharingEnabled: false,
      isUrlActionsEnabled: false,
      isGridActionsEnabled: false,
      isFiltersEnabled: false,
    })
    dispatch(setPageSection({ value: 'home.loggedIn' }))
    dispatch(setPageNumber(1))
    dispatch(setIdentifier(IntoNode.FOR_YOU))
    dispatch(setStream(StreamType.NODE_STREAM))
    dispatch(setInstanceId(_instanceId))
    dispatch(setPageSize(feedConstants.FULL_SCREEN_PAGE_SIZE))
    dispatch(setIsAutoplayEnabled(false))
  }

  return {
    props: {
      pageName: 'home',
      isLoggedIn,
      isDesktop: browserInfo.isDesktop,
    },
  }
})
